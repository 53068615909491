<template>
    <div class="account_authhority">
        
        <div class="page-header">
            <el-row>
                <el-col :span="24" align="right">
                    <el-button type="primary" round>Add Branch</el-button>
                </el-col>
            </el-row>
        </div>

        <el-row :gutter="20">
            <el-col :span="24">
                <el-card shadow="always">
                    <el-table
                    :data="tableData"
                    style="width: 100%">
                        <el-table-column
                            prop="id"
                            label="ID"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="branch_name"
                            label="Branch Name">
                        </el-table-column>
                        <el-table-column
                            prop="branch_address"
                            label="Branch address">
                        </el-table-column>
                        <el-table-column
                            prop="country"
                            label="Country">
                        </el-table-column>
                        <el-table-column
                            prop="contact_number"
                            label="Contact Number">
                        </el-table-column>
                        <el-table-column label="Action" align="right">
                            <template #default="scope">
                                <el-button
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)">Edit</el-button>
                                <el-popconfirm
                                @confirm="handleDelete(scope.row, scope.$index)"
                                title="確定要刪除嗎？">
                                    <template #reference>
                                        <el-button type="danger" size="small">Delete</el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>

        <el-dialog title="Branch information" v-model="dialogFormVisible">
            <el-form :model="form">
                <el-form-item label="Branch name" :label-width="formLabelWidth">
                    <el-input v-model="form.branch_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Branch address" :label-width="formLabelWidth">
                    <el-input v-model="form.branch_address" autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item label="Country" :label-width="formLabelWidth">
                    <el-select v-model="form.region" placeholder="Select">
                        <el-option label="中國" value="中國"></el-option>
                        <el-option label="香港" value="香港"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Contact Number" :label-width="formLabelWidth">
                    <el-input v-model="form.contact_number" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Email" :label-width="formLabelWidth">
                    <el-input v-model="form.email" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">Save</el-button>
                </span>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import { ElMessage } from 'element-plus'
  export default {
      name: 'authority',
      data() {
        return {
          tableData: [{
            id: '01',
            branch_name: 'Lorem',
            branch_address: 'Lorem ipsum',
            country: 'Lorem ipsum',
            contact_number: 'xxxxxxxxxxx'
          }, {
            id: '02',
            branch_name: 'Lorem',
            branch_address: 'Lorem ipsum',
            country: 'Lorem ipsum',
            contact_number: 'xxxxxxxxxxx'
          }, {
            id: '03',
            branch_name: 'Lorem',
            branch_address: 'Lorem ipsum',
            country: 'Lorem ipsum',
            contact_number: 'xxxxxxxxxxx'
          }, {
            id: '04',
            branch_name: 'Lorem',
            branch_address: 'Lorem ipsum',
            country: 'Lorem ipsum',
            contact_number: 'xxxxxxxxxxx'
          }],
          form: {
            branch_name: 'Lorem',
            branch_address: 'Lorem ipsum',
            country: 'Lorem ipsum',
            contact_number: 'xxxxxxxxxxx',
            email: 'xxxxxxxxxxx'
          },
          dialogFormVisible: false,
          formLabelWidth: '140px'
        }
      },
      methods: {
        handleEdit(index, row) {
            this.dialogFormVisible = true;
            console.log(index, row);
        },
        handleDelete(row, index) {
            ElMessage.success({
                message: '刪除成功',
                type: 'success'
            });
            this.tableData.splice(index, 1)
        },
      }
    }
</script>